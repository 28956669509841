import "core-js/modules/es.array.push.js";
import { order_page, getSchoolActiveInfo, base_order_check_all, exportOrders, adminRefundOrder } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      Info: [],
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        order_no: '',
        state: 1,
        check_state: '',
        operator_server_active_id: '',
        current: 1,
        total: 0
      },
      refundInfo: {
        order_id: '',
        refund_admin_money: '',
        refund_remark: ''
      },
      schoolActive: [],
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.pageInfo.operator_server_active_id = data.id;
      this.getList();
    }
  },
  methods: {
    reset() {
      this.pageInfo.order_no = '';
      this.pageInfo.check_state = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      this.closeDiv();
      order_page(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getSchoolActiveInfo() {
      getSchoolActiveInfo({
        id: this.pageInfo.school_active_id
      }).then(res => {
        this.schoolActive = res.data;
      });
    },
    checkAllOrder() {
      this.$root.ElMessageBox.confirm('你确定要核销该活动的全部订单吗？该操作可能会花费很长时间请耐心等待。', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        base_order_check_all({
          operator_server_active_id: this.pageInfo.operator_server_active_id
        }).then(res => {
          if (res.success) {
            this.$root.ElMessage({
              type: 'success',
              message: res.msg
            });
            this.search();
          } else {
            this.$root.ElMessage.error(res.msg);
          }
        });
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/base/activeApply/activeApplyInfo",
        query: {
          id: id
        }
      });
    },
    showRenew(row) {
      this.Info = row;
      this.refundInfo = {
        order_id: row.id,
        refund_admin_money: row.pay_money,
        refund_remark: ''
      };
      this.dialogFormVisible = true;
    },
    adminRefundOrder() {
      if (this.refundInfo.refund_admin_money < 0.01 || this.refundInfo.refund_admin_money > this.Info.pay_money) {
        this.$root.ElMessage.error('请输入合法的退款金额，至少0.01元，不能超过订单的实付金额');
        return false;
      }
      this.$root.msgTalk('退款将同步退款订单所使用和赠送的优惠券，你确定要退款吗？', this, 'adminRefundOrderImp', '');
    },
    adminRefundOrderImp() {
      adminRefundOrder(this.refundInfo).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    exportOrders() {
      exportOrders(this.pageInfo).then(res => {
        this.$root.downUrlFile(res, '活动订单列表.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
    }
  }
};